<template>
  <div class="case">
    <!-- <Banner :list="banners" :hadFrom="true"></Banner> -->
    <div class="case-main mt-2 container">
      <div class="case-menu">
        <template v-for="(item, index) in categoryList">
          <div
            class="item"
            :class="{ active: professionId == item.id }"
            :key="index"
            @click="changeCate(item.id)"
          >
            {{ item.name }}
          </div>
        </template>
      </div>
      <div class="case-list">
        <template v-for="(item, index) in caseList">
          <div class="item" :key="index" @click="toArticle(item.id, item.flag)">
            <div class="item-top">
              <div class="item-content pr-3">
                <h3 class="title">{{ item.title }}</h3>
                <p class="desc mt-1 mb-2">
                  {{ item.guide }}
                </p>
                <div class="info pt-2">
                  <p class="date">
                    办理周期：<span>{{ item.cycle }}</span>
                  </p>
                  <p>
                    费用：
                    <a-button class="btn" @click.stop="$toConnectLink">
                      获取报价>>
                    </a-button>
                  </p>
                </div>
              </div>
              <div
                class="item-img"
                :style="{ backgroundImage: 'url(' + item.cover_url + ')' }"
              >
                <img :src="item.cover_url" />
              </div>
            </div>

            <div class="m-info">
              <p class="date">
                办理周期：<span>{{ item.cycle }}</span>
              </p>
              <p>
                费用：
                <a-button class="btn" @click.stop="$toConnectLink">
                  获取报价>>
                </a-button>
              </p>
            </div>
          </div>
        </template>

        <template v-if="caseList.length == 0">
          <div class="no-case-list">
            <img src="../../assets/image/noDate.png" />
          </div>
        </template>
      </div>
    </div>
    <div class="policy-page">
      <a-pagination
        @change="selectPage"
        hideOnSinglePage
        :current="page"
        :pageSize="limit"
        :total="total"
      />
    </div>
  </div>
</template>

<script>
import Banner from "@/components/common/banner";
export default {
  data() {
    return {
      // banners: {
      //   title: "",
      //   desc: "",
      //   list: [require("@/assets/image/banner/1.jpg")],
      // },

      limit: 8,
      page: 1,
      total: 0,
      caseList: [],
      professionId: 0,
      categoryList: [
        {
          id: 0,
          name: "全部",
        },
      ],
    };
  },
  methods: {
    // getBannerList() {
    //   this.$api
    //     .getBannerList({
    //       // 10首页  20
    //       launchPage: 60,
    //       launchFacility: 10,
    //     })
    //     .then((res) => {
    //       this.banners.list = res.data.list;
    //     });
    // },
    changeCate(id) {
      this.professionId = id;
      this.page = 1;
      this.getCaseList();
    },
    toArticle(id, flag) {
      this.$router.push({
        name: "article",
        query: {
          id: id,
          flag: flag,
          professionId: this.professionId,
        },
      });
    },
    getCaseList() {
      this.$api
        .getArticleList({
          flag: "case",
          professionId: this.professionId,
          page: this.page,
          limit: this.limit,
          search: this.$route.query.name,
        })
        .then((res) => {
          this.caseList = res.data.list;
          this.total = res.data.total;
        });
    },
    getCategoryList() {
      this.$api.getArticleProfession().then((res) => {
        this.categoryList.push(...res.data);
      });
    },
    selectPage(index) {
      console.log(index);
      this.page = index;
      this.getCaseList();
    },
  },
  components: {
    Banner,
  },
  created() {
    // this.getBannerList();
    this.getCaseList();
    this.getCategoryList();
  },
  watch: {
    $route(val) {
      this.getCaseList();
      // console.log(val);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/css/case.scss";
</style>
